import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

export default () => {
  return (
    <Layout>
      <SEO title="Heel pain therapy and plantar fasciitis" />
      <ServicesLayout title="Heel pain therapy and plantar fasciitis">
        <h2>What is plantar fasciitis?</h2>
        <p>
          Commonly Plantar Fasciitis: an inflammation caused by excessive
          stretching and tearing of the broad band of fibrous tissue which runs
          along the bottom surface of the foot. This band; the plantar fascia
          attaches to the heel bone and extends to the forefoot. Plantar
          Fasciitis causes heel pain, arch pain and heel spurs.
        </p>
        <h2>Achillies tendonitis - another cause of heel pain</h2>
        <p>
          Achillies Tendonitis is inflammation following damage to the Achillies
          tendon. It is a common complaint resulting in pain on the back of the
          heel bone, the tendon, and aslo pain up in the calf mucles. The pain
          presents in children following growth spurts and in adults following
          sudden exercise or injury. Promt treatment is vital for an optimum
          outcome.
        </p>
        <h2>
          How to treat heel pain from plantar fasciitis or achillies tendonitis
        </h2>
        <p>
          Your podiatrist will implement a treatment plan based on their
          assessment results. This treatment plan may involve
          dry-needling/acupuncture, accupressure, cold-spray and stretches,
          taping, orthotics, footwear assessment and modification.
        </p>
        <BookButton text="Get rid of my heel pain now" size="1.5rem" />
      </ServicesLayout>
    </Layout>
  )
}
